.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div, section, main {
  box-sizing: border-box;
}

main {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 20px;

}

main .recipe {
  position: relative;
} 

main .recipe button.addToWL {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    border: none;
    border-radius: 50px;
}

.aToWeekList {
  position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 25px;
}

.searchSection {
    padding: 10px;
    padding-top: 120px;
    padding-bottom: 10px;
    text-align: left;
    background-color: rgb(255, 242, 217);
}

.metawrapper {
    text-align: left;
    width: 96%;
    margin: 0 auto;
        margin-bottom: 25px;
    margin-top: 10px;
    padding-bottom: 5px !important;
}

.recipeDetails > div.buttons button {
margin-top: 10px;
    padding: 5px 10px;
    background-color: gray;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    margin-left: 5px;
}

.metawrapper > h1 {
  padding-bottom: 5px;
}

.metawrapper > span {
  margin-right: 15px;
  background-color: goldenrod;
  color: white;
  padding: 5px 7px;
  border-radius: 3px;
}

.metawrapper > span:empty {
  display: none;
}


.recipeDetails {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: white;
  border: 4px solid black;
  max-width: 100vw;
  max-height: 100vh;
  overflow-y: auto;
  margin-top: 52px;
  padding-bottom: 50px;
}

.recipeDetails span {
  text-align: left;
}

.recipeDetails h1 {
    margin-bottom: 5px;
} 

.recipeDetails > img {
    width: 100%;
    height: 33vh;
    overflow: hidden;
    object-fit: cover;
        border-bottom-right-radius: 50% 25%;
  border-bottom-left-radius: 50% 25%;
}

.closeMe {
  position: fixed;
  display: block;
    background-color: white;
    padding: 0.5rem 0.8rem;
    border-radius: 100%;
    box-shadow: rgb(43 83 135 / 8%) 0px 3px 8px 0px;
    right: 15px;
    top: 67px;
}

.subtitle {
  font-size: 16px;
      font-family: "Work Sans";
    line-height: 2.11;
    letter-spacing: -0.3px;
    color: rgb(0, 51, 78);
    margin-bottom: 0px;
    opacity: 0.8;
    font-weight: 500;
    text-align: center;
}

.searchSection h1 {
  text-align: center;
}

.searchForm {
  max-width: 100%;
  display: flex;
  flex-direction: row;
      justify-content: center;
    align-items: center;
    padding: 50px 50px;
    padding-top: 25px;
}

.searchForm button {
    background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 5px;
  border: 1px solid gray;
}

.searchForm input {
    width: 100%;
    background-color: rgb(255, 255, 255);
    border: none;
    outline: none;
    height: 50px;
    border-radius: 5px;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: rgba(0, 51, 78, 0.4);
    padding-left: 15px;
    transition: all 500ms ease 0s;
} 

.removeItem {
  background-color: transparent !important;
  padding: 0px 20px !important;
}

ul.ingredienten {
  list-style: none;
  padding-left: 2rem;
  padding-right: 2rem;
}

ul.ingredienten li {
  border-bottom: 1px solid gray;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

ul.ingredienten li:last-of-type { 
  border-bottom: none;
}

ul.ingredienten > h3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sideBarNav {
  display: none;
  position: absolute;
  width: 50vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  left: 100vw;
}

.sideBarNav.openNav {
  left: 50vw;
}

.hamburger {
  cursor: pointer;
}

@media only screen and (max-width: 678px) { 

    
}

nav {
    top: 0px;
    left: 0px;
    width: 100%;
    transition: all 0.35s ease-in-out 0s;
    padding: 10px 0px;
    display: flex;
    justify-content: end;
    position: fixed;
    padding-right: 10px;
    align-items: center;
    background-color: rgb(255, 242, 217);
    box-shadow: rgb(43 83 135 / 8%) 0px 3px 8px 0px;
    z-index: 99999;
}


nav a {
      border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    background-color: rgb(255, 242, 217);
    border-radius: 5px;
    color: rgb(0, 51, 78);
    padding: 0px 13.5px;
    height: 30px;
    transition: all 500ms ease 0s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-width: 48px;
    margin-right: 10px;
    font-size: .85rem;
    text-decoration: none;
    border: 1px solid gray;
}


.weeklistSection {
  position: fixed;
  top: 52px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: purple;
  max-width: 100vw;
}

.weeklistSection {
  padding: 10px;
  background-color: #f8f8f8;
    padding-top: 60px;
  overflow-y: auto;
  white-space: nowrap;

}

.hideMe {
  display: none;
}

.accountPage {
    position: absolute;
    top: 0;
    padding-top: 80px;
    left: 0;
    right: 0;
    height: 100%;
    max-height: 100vh;
    z-index: 9999;
    background-color: white;
    overflow: hidden;
    overflow-y: unset;
}

.weeklistSection .weeklist {
  display: flex;
  width: 90%;
  margin: 10px;
  padding: 0px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
      flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.weeklistSection .weeklist img {
height: 200px;
    border-radius: 10px;
    width: 40%;
    min-width: 40%;
    max-height: 7rem;
    object-fit: cover;
}

.weeklistSection .weeklist h1 {
    min-width: 60%;
    max-width: 60%;
    margin-left: 5px;
    margin-top: 0;
    font-size: 15px;
    text-align: left;
    word-wrap: break-word;
white-space: pre-wrap;
padding-right: 1rem;
word-break: break-word;
box-sizing: border-box;
}

.ingredientsList {
  position: fixed;
      overflow-y: scroll;
      padding-bottom: 60px;
  margin-top: 52px;
  max-height: 95vh;
  padding-top: 25px;
  left: 0;
  top:  0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.closeMe.igList {
  top: 42px;
}

.copyBtn {
  margin-bottom: 50px;
  z-index: 9999;
} 

.ingredientsList h2 {
  margin-top: 0;
}

.ingredientsList ul {
  list-style: none;
  padding: 0;
  width: 85%;
}

.ingredientsList li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: lightyellow;

}

.weekListLeeg {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.weeklistSection .weeklist button {
  border: none;
  background-color: #ff4d4d;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

      font-size: 12px;
    padding: 5px 10px;
    margin-top: 5px;

    position: absolute;
    top: 5px;
    border-radius: 5px 0 0 5px;
    padding-right: 6.5px;
}

.weeklist > span {
      position: absolute;
    left: 5px;
    bottom: 5px;
    background-color: white;
    border-radius: 5px;
    padding: 0px 5px;
}

.weeklistSection .weeklist button:hover {
  background-color: #e60000;
}

.weeklistSection .weeklistSection .weekList {
  border: 2px solid white;
    display: flex;
}

.weeklistSection .weeklistSection button.randomBtn {
    position: absolute;
    bottom: 20px;
    margin: 0 auto;
    width: 100%;
    left: 0;
}

.recipe > img {
  width: 100%;
  height: 200px;
  border-radius: 15px;
  object-fit: cover;

}

.recipe > h3 {
  padding: 0 !important;
    text-align: left;
    margin-top: 10px;
    margin-left: 5px;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
}

.recipe > h4 {
      font-size: 12px;
    text-align: left;
    color: gray;
    margin: 0;
    padding: 0 !important;
    margin-left: 5px;
}


ol.steps {
  text-align: left;
  padding-right: 25px;
}

ol.steps li {
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
  margin-bottom: 10px;
}

ol.steps li:last-of-type {
  border-bottom: none !important;
}

.ingredientenBlock {
  background-color: papayawhip;
    width: 96%;
    margin: 0 auto;
    border-radius: 10px;
    text-align: left;
    padding: 1px 0;
}

.theForm {
  position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 99999;
    max-width: 100vw;
    max-height: 100vh;
    overflow-y: scroll;
    margin-top: 52px;
    padding-bottom: 70px;
}



 .theForm h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

 .theForm form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 30px;
  }

 .theForm label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    font-weight: bold;
    width: 80%;
  }

  .theForm input[type="text"],
  .theForm textarea,
 .theForm select {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 5px;
    width: 100%;
    box-sizing: border-box;
    width: 100%;
  }

  .theForm input[type="file"] {
    margin-top: 10px;
    width: 100%;
  }

  .theForm button[type='submit'] {
    margin-bottom: 50px;
    width: 100%;
    max-width: 50vw;
  }

  .theForm input {
    width: 100%;
  }

  .theForm label > div {
        display: flex;
    width: 100%;
  }

  .theForm button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #0066cc;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  .theForm button:hover {
    background-color: #004080;
  }

  .closeMe {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    z-index: 999;
  }
